import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import GatsbyImage from 'common/components/GatsbyImage';
import SectionWrapper, { ContentWrapper } from './privacy.style';

const PrivacyPortal = () => {
  const Data = useStaticQuery(graphql`
    query {
      illustration: file(
        relativePath: { eq: "image/cryptoModern/illustration1.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  return (
    <SectionWrapper>
      <Container>
        <ContentWrapper>
          <div className="content">
            <Heading content="Privacy Policy" />
            <h2>Last updated date October 01, 2024</h2>
            
            <p>This Privacy Policy (the “Privacy Policy”) serves to inform you of our policies and procedures regarding the collection, use and disclosure of the information we receive when you access and use the website (the “Site”), services, software, content, and all other products and services (all of the foregoing collectively, the “Services”) owned, controlled or offered, directly or indirectly, by NADIAI INC. (together with its subsidiaries and affiliates, “NadiAI Inc.”, “we,” “our” or “us”). NadiAI Inc. believes that the privacy of its users is paramount and strives to use Personal Information (as defined below) only in ways outlined in this Privacy Policy. The Privacy Policy is incorporated by reference into our Terms of Service, currently available at https://nadiai.ai/terms (the “Terms of Service”). Any terms used herein and not defined will have the meanings given to them in the Terms of Service. By using the Services, you hereby warrant and represent that you have read, understand and agree to this Privacy Policy and the Terms of Service and that you are over 18 years of age. PLEASE DO NOT USE, INSTALL OR ACCESS THE SERVICES IF YOU DO NOT AGREE TO THIS PRIVACY POLICY.</p>
     
        <ol>
          <li><span class="h4">What We Mean by Personal Information</span>
            <p>For purposes of this Privacy Policy, “Personal Information” means any information from or about a person that either identifies that person directly or that makes that person identifiable when it is combined with other information from or about that person from any source.</p>
          </li>
          <li><span class="h4">Information We Collect</span>
            <p><i>Information You Provide To Us</i></p>
            <p>When you use the Services or otherwise communicate with us, we collect information that you provide to us directly. For example, we collect information in the following circumstances: you (i) use the NadiAI Platform or visit the Site; (ii) register for the Services or create an account or profile or accept the creation of an account or profile on your behalf (an “Account”); (iii) access the Services through social networking sites or third party services; (iv) subscribe to newsletters; (v) purchase a product or service on the Site; (vi) invite your friends to join the Services, “share” the Services on social networking sites or perform other similar actions; (vii) request technical support; (viii) otherwise use our Services and/or the NadiAI Platform where Personal Information is required for such use and/or participation; (ix) when you sign up for a newsletter; and (x) when you contact us via the Site or when you otherwise communicate with us. You can choose not to provide information that is requested of you by us; however, this may limit your ability to use or access the Services.
</p>
            <p>The information you provide to us directly may include, without limitation, the following information that may, alone or in combination with other data, constitute Personal Information:</p>
            <ul>
              <li>Information you provide via email, through the “Contact” section on the Site or by using the contact details listed on various parts of the Site, including your name, e-mail, phone number, address, and any other information you decide to provide;</li>
              <li>If you are one of our Users, suppliers, or prospects, we may process certain Personal Information in the course of our business relation with you, for example when you disclose diligence data to the NadiAI Platform, subscribe to the NadiAI Platform, request a demo or vice versa. Such Personal Information may include your name, company, title, e-mail address, telephone number, address, race, ethnicity, gender, and order details; </li>
              <li>Information you provide that is included in User Generated Content you upload or provide for use with the AI Functions, and, since we have no direct relationship with nay person other than you, you are responsible for making sure that you have the appropriate permission and legal basis for us to collect and process User Generated Content; and</li>
              <li>Any other information you may want to share with us, which may include Personal Information. </li>
              <li>We do not use data obtained through Google Workspace APIs to develop, improve, or train generalized AI and/or machine learning models. All data processed through these APIs is handled in accordance with Google's API terms of service and solely for the intended functionality of our application.</li>
            </ul>
            <p>Moreover, if you contact us, a record of such correspondence may be kept. </p>
            <p>Additionally, if a User refers you to us by providing us with your e-mail address, we may send you a message. We will use your e-mail address only to send such other User's message unless you otherwise affirmatively provide your information to us.</p>   
            <p><i><strong>Automatically Collected Information</strong></i></p>
            <p>Most of the data we collect in and through the Site and the Services is technical in nature and is collected and processed automatically through so-called application programming interfaces, software development kits, cookies and similar software-based technologies. Alone or in combination with other data, such automatically collected data may constitute Personal Information. The data we may collect by automated means may include, without limitation:</p>
            <ul>
              <li>Device data: including, but not limited to, data on device advertising ID's and similar hardware qualifiers, and precise location data.</li>
              <li>Usage data: including, but not limited to, search terms entered and pages viewed. </li>
              <li>Network and internet information: including, but not limited to, URLs, Internet Protocol addresses, bounce rates, use of spoofing, active (TCP/IP) ports, number of sessions initiated, click streams, location information and network/Wi-Fi access points.</li>
              <li>Information we collect on the use of the Site via cookies: please see the “How We Use Cookies and Other Technologies” section below for more information. </li>
            </ul>
            <p>Google Analytics is an element of the Site. By using cookies, Google Analytics collects and stores data such as time of visit, pages visited, time spent on each page of the Site, the Internet Protocol address, and the type of operating system used in the devices used to access the Site. By using a browser plugin available at http://www.google.com/ads/preferences/plugin/ provided by Google, you can opt out of Google Analytics.</p>
            <p><i><strong>Information You Post on the Services </strong></i></p>
            <p>The NadiAI Platform may allow for Users to provide information via the Site for NadiAI to incorporate into the NadiAI Platform. Information that you provide through the use of these tools will be available to the public generally. NadiAI has no obligation to keep private any information that you disclose to other Users or the public using these functions. You should exercise caution when deciding to disclose Personal Information through the use of these tools or functions. We strongly recommend that you do not post any information through the Services that allows strangers to identify or locate you or that you otherwise do not want to share with the public.</p>
            <p><i><strong>Information You Share on Third Party Websites or through Social Media Services</strong></i></p>
            <p>The Services may include links to third party websites and social media services where you will be able to post comments, stories, reviews or other information. Your use of these third party websites and social media services may result in the collection or sharing of information about you by these third party websites and social media services. We encourage you to review the privacy policies and settings on the third party websites and social media services with which you interact to make sure you understand the information that may be collected, used, and shared by those third party websites and social media services.</p>
            <p><i><strong>How We Use Cookies and Other Technologies </strong></i></p>
            <p>Some of the features on the Site and the Services require the use of “cookies” - small text files that are stored on your device's hard drive. We use cookies to measure which pages are being accessed, and which features are most frequently used. This enables us to continuously improve the Site to meet the needs of our visitors.</p>
            <p>The following sets out how we may use different categories of cookies and your options for managing cookie settings:</p>
            <table class="privacy-tbl" border="1">
              <tr>
                <th width="20%">Type of Cookies</th>
                <th width="60%">Description</th>
                <th width="20%">Managing Settings</th>
              </tr>
              <tr>
                <td><strong>Required cookies</strong></td>
                <td>Required cookies enable you to navigate the Site and use their features, such as accessing secure areas of the Site and using the Services. If you have chosen to identify yourself to us, we use cookies containing encrypted information to allow us to uniquely identify you. These cookies allow us to uniquely identify you when you are logged into the Site and to process your online transactions and requests.</td>
                <td>Because required cookies are essential to operate the Site, there is no option to opt out of these cookies.</td>
              </tr>

            </table>
            <p>We and our service providers may also use “pixel tags,” “web beacons,” “clear GIFs,” or similar means in connection with the Services and HTML-formatted email messages to, among other things, track the actions of Users, to determine the success of marketing campaigns and to compile aggregate statistics about Site usage and response rates. </p>
          </li>
          <li><span class="h4">Use of Collected Information</span>
            <p>We use the information you provide to us for the following purposes: (i) to further our legitimate interests in providing the Services, including without limitation to collect and remit payment for paid features; (ii) to administer your use of the Services and any Accounts you may have with us; (iii) to personalize your experience; (iv) to provide to you Service announcements or inform you of new releases and features; (v) to provide you with further information and offers from us that we believe you may find useful or interesting, such as newsletters, marketing or promotional materials; (vi) to perform tasks on behalf of and according to instructions of a third party, such as payment processors, third party-service providers or our advertising partners; (vii) to enforce our Terms of Service; (viii) to resolve any disputes between users of the Site and us; (ix) comply with a legal requirement or process, including, but not limited to, civil and criminal subpoenas, court orders or other compulsory disclosures; (x) to further our legitimate interest in protecting our rights, property, or safety and the rights, property and safety of the Services, our Users or the public; and (xi) to contact you to obtain feedback from you regarding the Site and the Services. </p>
            <p>In addition to the purposes described above, we use the information collected automatically to: (i) to further our legitimate interests in monitoring and analyzing the use of the Services and for the technical administration of the Site; (ii) train and improve the Site and the Services and the AI Functions; (iii) develop and improve other products and services; (iv) generate and derive useful data and information concerning the interests, characteristics and website use behavior of our Users; and (v) verify that users of the Services meet the criteria required to process their requests.</p>
          </li>
          <li><span class="h4">Third Parties We Share Personal Information With</span>
            <p>We may disclose Personal Information you provide to us or that we collect automatically on the Site and in and through the Services with the following categories of third parties:
            <ul>
              <li>Service providers, such as payment processors, web hosting and data storage service providers, marketing service providers, communication services providers, communications service providers (e.g. OpenAI, L.L.C.) and other services providers helping us deliver and develop the Services;</li>
              <li>Public authorities, such as law enforcement, if we are legally required to do so or if we need to protect our rights or the rights of third parties; and</li>
              <li>Our subsidiaries and affiliates; or a subsequent owner, co-owner or operator of the Site and/or the Services and their advisors in connection with a corporate merger, consolidation, restructuring, the sale of substantially all of our stock and/or assets, or in connection with bankruptcy proceedings, or other corporate reorganization, in accordance with this Privacy Policy.</li>

            </ul>
            </p>
          </li>
          <li><span class="h4">Links to Other Websites</span>
            <p>We frequently make content or services from other websites available to you from links located on the Site. We may present links in a format that enables us to keep track of whether these links have been followed. In addition, you may opt to link your Account to your social media account or other accounts, such as Google or LinkedIn or OpenAI, in accordance with your Account settings (which you may change at any time) and respective policies of these third parties. This Privacy Policy applies only to the Site and the Services. We do not exercise control over third party services or other websites that provide information or links from within the Site or the Services. Your interactions with these third party services are governed by the privacy policy of the company providing it. These other sites and services may place their own cookies or other files on your computer's browser, collect data or solicit Personal Information from you. Other websites and services follow different rules regarding the use or disclosure of the Personal Information that you submit, and the collection and use of such information and access of any third party websites are subject to such third party's privacy policy. We encourage you to read the privacy policies and other terms of such third parties before using their services.</p>
            
          </li>
          <li><span class="h4">Security</span>
            <p>We understand the importance of privacy and security of Personal Information to our Users and have made them a priority. NadiAI Inc. uses a variety of industry-standard security technologies and procedures to help protect Personal Information about you from unauthorized access, use, or disclosure and trains NadiAI Inc. employees on privacy and security issues. However, we cannot guarantee that unauthorized third parties will never be able to overcome those measures or use your Personal Information for improper purposes, and we do not promise that Personal Information about you or private communications will be protected from unauthorized disclosure or use. </p>
          </li>
          <li><span class="h4">Do Not Track Signals and Similar Mechanisms</span>
            <p>NadiAI Inc. does not track its customers over time and across third party websites to provide targeted advertising and therefore does not respond to Do Not Track (DNT) signals. </p>
           
          </li>
          <li><span class="h4">Managing Your Privacy</span>
            <p>We keep your data on your behalf and for your benefit. You can correct or terminate and delete your Account information by following the instructions on the Services or by e-mail at <a href="mailto:support@nadiai.ai">support@nadiai.ai</a>, which you may also access for further assistance and questions regarding the Privacy Policy or for a copy of your Account data. If you cancel your Account or request us to delete your information, NadiAI Inc. will limit its access to Personal Information to perform what is requested by you and will delete information accessible to NadiAI Inc. within seven business days.</p>
          </li>
          <li><span class="h4">Your Rights</span>
            <p>We take steps to help ensure that you are able to exercise your rights regarding Personal Information about you in accordance with applicable law. If you would like to access, review, erase, withdraw your consent, or object to, or restrict the processing of Personal Data we collect about you, you may submit a request to <a href="mailto:support@nadiai.ai">support@nadiai.ai</a>. We will promptly review all such requests in accordance with applicable laws. Depending on where you live, you may also have a right to lodge a complaint with a supervisory authority or other regulatory agency if you believe that we have violated any of the rights concerning Personal Information about you. We encourage you to first reach out to us at <a href="mailto:support@nadiai.ai">support@nadiai.ai</a>, so we have an opportunity to address your concerns directly before you do so.</p>
           
          </li>
          <li><span class="h4">California Residents</span>
            <p>California Civil Code Section 1798.83 requires certain businesses that share customer Personal Information with third parties for the third parties' direct marketing purposes to respond to requests from California customers asking about the businesses' practices related to such information-sharing. We currently do not share or disclose your Personal Information to third parties for the third parties' direct marketing purposes. If we change our practices in the future, we will implement an opt-out policy as required under California laws.</p>
            <p>Furthermore, subject to certain exemptions, California residents have the following rights with respect to Personal Information we may have collected about them: </p>
            <p><i><strong>Requests to Know</strong></i></p>
            <p>You have the right to request that we disclose: </p>
            <ul>
              <li>The categories of Personal Information we have collected about you;</li>
              <li>The categories of Personal Information about you we have sold or disclosed for a business purpose;</li>
              <li>The categories of sources from which we have collected Personal Information about you;</li>
              <li>The business or commercial purposes for selling or collecting Personal Information about you;</li>
              <li>The categories of Personal Information sold or shared, if any, about you, as well as the categories of third parties to whom the Personal Information was sold, by category of Personal Information for each party to whom Personal Information was sold; and</li>
              <li>The specific pieces of Personal Information collected.</li>
            </ul>
            <p>You may submit a request to know via <a href="mailto:support@nadiai.ai">support@nadiai.ai</a>. The delivery of our response may take place electronically or by mail. We are not required to respond to requests to know more than twice in a 12-month period.</p>
            <p>We do not sell, and have not in the prior 12 months sold, Personal Information about California residents. Therefore, we have not included a “Do Not Sell My Personal Info” link on our Site. If our practices change, we will update this Privacy Policy and take any other necessary action to comply with applicable law. We do, however, disclose Personal Information for business purposes as described in the “Third Parties We Share Personal Information With” section above.</p>
            <p><i><strong>Requests to Delete</strong></i></p>
            <p>You have the right to request that we delete any Personal Information about you that we have collected. Upon receiving a verified request to delete Personal Information, we will do so unless otherwise required or authorized by law. You may submit a request to delete Personal Information via <a href="mailto:support@nadiai.ai">support@nadiai.ai</a>.</p>
            <p><i><strong>Authorized Agents</strong></i></p>
            <p>You may designate an authorized agent to make requests on your behalf. You must provide an authorized agent with written permission to submit a request on your behalf, and we may require that you verify your identity directly with us. Alternatively, an authorized agent that has been provided power of attorney pursuant to Probate Code sections 4000-4465 may submit a request on your behalf.</p>
            <p><i><strong>Methods for Submitting Consumer Requests and Our Response to Requests</strong></i></p>
            <p>You may submit a request for access and requests to delete Personal Information about you via:</p>
            <ul><li>via email at <a href="mailto:support@nadiai.ai">support@nadiai.ai</a>.</li></ul>
            <p>Upon receipt of a request, we may ask you for additional information to verify your identity. Any additional information you provide will be used only to verify your identity and not for any other purpose.</p>
            <p>We will acknowledge the receipt of your request within 10 days of receipt. Subject to our ability to verify your identity, we will respond to your request within 45 days of receipt. If we require more time (up to 90 days), we will inform you of the reason and extension period in writing. In order to protect your privacy and the security of Personal Information about you, prior to taking action on any request we will verify your identity. We may require you to provide additional information for verification purposes.</p>
            <p>Any disclosures we provide will only cover the 12-month period preceding the verifiable consumer request's receipt. The response we provide will also explain the reasons we cannot comply with a request, if applicable.</p>
            <p>We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive, repetitive, or manifestly unfounded. If we determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request.</p>
            <p><i><strong>The Right to Non-Discrimination</strong></i></p>
            <p>You have the right not to be discriminated against for the exercise of your California privacy rights described above. Unless permitted by the CCPA, we will not:</p>
            <ul>
              <li>Deny you goods or services;</li>
              <li>Charge you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties;</li>
              <li>Provide you a different level or quality of goods or services; or</li>
              <li>Suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or services.</li>
            </ul>
          </li>
          <li><span class="h4">International Transfers of Personal Information</span>
            <p>NadiAI Inc. is based in the United States. As a result, Personal Information that we collect in and through the Services and on the Site may be transferred to our U.S. offices. In addition, we may work with third-party service providers in the U.S. and in other countries to support our business activities. Thus, Personal Information may be transferred to, stored on servers in, and accessed from the United States and countries other than the country in which the Personal Information was initially collected. In all such instances, we use, transfer, and disclose Personal Information solely for the purposes described in this Privacy Notice and in compliance with applicable laws.</p>
          </li>
          <li><span class="h4">Data Retention</span>
            <p>We keep Personal Information related to your Account for as long as it is needed to fulfill the purposes for which it was collected, to provide our services, to deal with possible legal claims, to comply with our business interests and/or to abide by all applicable laws. Thereafter, we either delete Personal Information about you or de-identify it. Please note that even if you request the deletion of Personal Information about you, we may be required (by law or otherwise) to retain the Personal Information and not delete it. However, once those requirements are removed, we will delete Personal Information about you in accordance with your request.</p>
          </li>
          <li><span class="h4">Changes to the Privacy Policy</span>
            <p>Our security and Privacy Policy are periodically reviewed and enhanced as necessary. This Privacy Policy might change as we update and expand the Services. You can tell when this Privacy Policy was last updated by reviewing the Last Updated-legend on top of this page. We will endeavor to notify you of these changes by email, but will not be liable for any failure to do so. We also encourage you to review this Privacy Policy periodically. If you do not understand any of the terms or conditions of any of our policies, you may inquire regarding the same via email at <a href="mailto:support@nadiai.ai">support@nadiai.ai</a>. Your continued use of the Services after any change in this Privacy Policy will constitute your acceptance of such change.</p>
          </li>
          <li><span class="h4"><strong>Contacting Us</strong></span>
            <p>If you have any concerns or questions about this Privacy Policy, please contact us at <a href="mailto:support@nadiai.ai">support@nadiai.ai</a>.</p>
          </li>  
          

        </ol>


            {/*<Text content="Lorem ipsum dolor sit amet consectetur adipisicing elit sed eiu Lorem ipsum dolor sit amet consectetur adipisicing elit sed eiu" />*/}
          </div>
          {/*<div className="image">
            <Fade up>
              <GatsbyImage
                src={
                  (Data.illustration !== null) | undefined
                    ? Data.illustration.childImageSharp.gatsbyImageData
                    : {}
                }
                alt="Privacy Illustration"
              />
            </Fade>
          </div>*/}
        </ContentWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default PrivacyPortal;
